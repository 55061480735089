import type { LoaderFunctionArgs } from '@remix-run/cloudflare'

import { cn } from '~/styles/utils'

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  // await context.authenticator.isAuthenticated(request, {
  //   failureRedirect: '/login',
  // })

  return null
}

export default function Page() {
  return (
    <div>
      <div className="card bg-white p-4 rounded-lg shadow-2xl shadow-lg text-gray-800">
        <h2
          className={cn(
            'font-sans text-4xl ',
          )}
        >
          hosgeldiniz!
        </h2>
      </div>
    </div>
  )
}
